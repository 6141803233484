import React, { memo } from 'react';
import PropTypes from 'prop-types';

import classNames from './titleHeader.module.scss';

const TitleHeader = ({ children }) => (
  <h1 className={classNames.title}>
    {children}
  </h1>
);

TitleHeader.displayName = 'TitleHeader';

TitleHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

TitleHeader.defaultProps = {};

export default memo(TitleHeader);
